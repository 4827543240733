<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="panel panel-primary">
                    <div class="panel-body">
                        <h3 class="panel-title">
                            <inline-svg :src="navbarInfo.icon" class="panel-icon" alt="" /> {{ navbarInfo.name }} 
                            <button class="btn bg-main btn-sm" @click="getHistory"><i class="fa fa-refresh"></i></button>
                        </h3>

                        <Notification :data="noti" :key="k" v-for="(noti, k) in notification.history"></Notification>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <select class="form-control" v-model="search.limit" @change="getHistory">
                                        <option :value="rows" v-for="rows in $store.state.limitRowsTable">{{ rows | tableRows }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" v-model="search.text" placeholder="Tìm kiếm...">
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Username</th>
                                        <th>Hoạt động</th>
                                        <th>Số dư</th>
                                        <th>Thời gian</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(his, k) in filter">
                                        <td>{{ (history.length - k) }}</td>
                                        <td>{{ his.username }}</td>
                                        <td>
                                            <input type="text" class="form-control" :value="his.content" style="width: 100%;display:inline-block;min-width: 350px;">
                                        </td>
                                        <td>
                                            <div v-if="his.calculation == 'minus'">
                                                <span class="label rounded b-info">{{ his.before_money | numberFormat }}</span>
                                                -
                                                <span class="label rounded b-danger">{{ his.after_money | numberFormat }}</span>
                                                =
                                                <span class="label rounded b-success">{{ his.before_money - his.after_money | numberFormat }}</span>
                                            </div>
                                            <div v-else>
                                                <span class="label rounded b-warning">{{ his.before_money | numberFormat }}</span>
                                                +
                                                <span class="label rounded b-info">{{ his.after_money | numberFormat }}</span>
                                                =
                                                <span class="label rounded b-success">{{ his.before_money + his.after_money | numberFormat }}</span>
                                            </div>
                                        </td>
                                        <td>{{ his.action_time | timeText }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="text-center" v-if="filter.length === 0">
                                <!-- <inline-svg :src="require('@/assets/images/icons/404.svg')" height="120" width="120" /> -->
                                <p class="font-weight-bold">Không tìm thấy danh sách...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                history: [],
                search: {
                    limit: 50,
                    text: ''
                }
            }
        },
        props: ['navbarInfo', 'notification'],
        created () {
            this.getHistory();
        },
        computed: {
            filter () {
                if (this.search.text) {
                    return this.history.filter((x) => {
                        return (
                            x.content.toLowerCase().includes(this.search.text.toLowerCase()) ||
                            x.username.toLowerCase().includes(this.search.text.toLowerCase())
                        );
                    });
                }
                return this.history;
            }
        },
        methods: {
            getHistory () {
                this.history = [];
                this.$http.post('history', this.search).then(res => {
                    this.history = res.body;
                });
            }
        }
    }
</script>